import React from "react";
import { Layout } from "../../../components";

const SerwisyInternetowe = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Serwisy internetowe",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/serwisy-internetowe/",
      }}
    >
      <section className="single_offer">
        <div className="single_offer__container">
          <div className="single_offer__header text-center">
            <h1>Web development</h1>
          </div>
          <div className="single_offer__content">
            <div className="single_offer__services">
              <p className="text-center">
                ergonomics - design - achieving goals
              </p>
              <p>
                Websites always pursue some goals, which is why we focus on their effectiveness and adequacy in the first place. We provide support in the field of design and implementation; corporate websites, landing pages, online stores and ecommerce solutions, portals and dedicated tailor-made applications.
              </p>
              <p className="margin-sm">
                Over the years, we have developed an implementation process that ensures efficient cooperation and a combination of ergonomics and good design, with the implementation of our Clients’ business goals.
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <h3>Why us?</h3>
                  <p className="margin-sm">
                    Experience. Well-thought-out design process. Recommendation of the solution that achieves the set goals. Our special project submission procedure.
                  </p>
                  SWide additional competences: Analytics, UX, Internet Marketing (SEO / SEM), Copywriting, and Website Security (ForSecure),
                </div>
                <div className="col-lg-6">
                  <h3>Full project realization process</h3>
                  <p>
                    Depending on the requirements or the nature of the project, all or selected design stages apply. We implement the entire process in the Quality Assurance model (clear communication, defined requirements, roles, and methods of work). 
                  </p>
                  <p>
                    <i>“By failing to prepare, you are preparing to fail.”</i>{" "}
                    <br /> Benjamin Franklin
                  </p>
                </div>
              </div>
              <h3>Briefing</h3>
              <p>
                We carry out a preliminary needs analysis, thanks to which we get to know Client’s business, industry, its offer and expectations regarding the project. We discuss functionalities, the initial scale of the project and its budget. All this so that both sides assess the potential value of cooperation.
              </p>

              <h3>Workshops</h3>

              <p>
                The key to success in implementing Internet projects is the stage of preparation for their implementation. At the beginning, when we only have an idea or general assumptions, we are not able to determine the scale of the project, its complexity, or the nature of all functionalities. It is extremely important to collect requirements, define and specify the functionalities and architecture of the website. To avoid any misunderstandings at the implementation stage, it is crucial to clearly define expected functions of the system. The aim of the workshops is to develop project documentation that will allow for the recommendation of an appropriate technology.
              </p>
              <h3>Project documentation</h3>
              <p>
                Based on the data collected during the briefing and the workshops, we are able to prepare the documentation of the project. This documentation is a binding set of functionalities, defines the scope of our work in detail, and constitutes a reference point for verifying that the final product meets the initial assumptions. The developed documentation becomes the property of our Clients.
              </p>
              <h3>Mockup / functional model</h3>
              <p>
                Based on the competition audit, good practices, user research, and our experience, we develop a functional model of the website. The mock-up is a sketch of the website showing its architecture and arrangement of elements. This is a model that we can navigate, move between pages, and simply get to know the structure of the website before it is created. This stage is extremely important as it contributes to the convenience of using the website and its effectiveness - it is at this stage that we develop the best solutions to ensure the achievement of Client’s business goals.
              </p>
              <h3>
                Creative concept: inspirations - benchmarks - creative recommendations
              </h3>
              <p className="margin-sm">
                Once we have full knowledge of the website's functionalities and its structure, we can start working on the graphic concept. We carry out research in our Client's industry and elsewhere. We collect inspirations and recommend the best direction in which the creation of the design should go in our opinion.
              </p>
              <p>
                Next, we develop a Style Guide - a set of website components (colors, typography, buttons etc.).
              </p>
              <h3>Design: attractive - thoughtful - adequate</h3>
              <p>
                We design key visualisations of the website, that is further consulted with our Client. After refining and accepting the key visualisations (e.g. homepage), we design all subpages in accordance to the developed core concept.
              </p>
              <h3>Implementation / Coding</h3>
              <p className="margin-sm">
                We believe that using one technology everywhere is not a good solution. We assume that it is the technology that must be selected and adapted to the project requirements, not the project to the technology, therefore a recommendation in this area is always individual and possible only after defining the real needs of our Client and project’s budget.
              </p>
              <p>
                As a part of the implementation, we code the front-end and back-end layers of the website, optimize its code, publish the content provided by the Client, and then move on to the culmination of our work, that is our proprietary 34-stage Project Submission  Procedure (P.S.P). We carry out the implementation stage taking into account the processes in the field of Quality Control (searching for errors, application testing).
              </p>
              <h3>Publishing the website</h3>
              <p>
                We transfer the website to the target environment (most often it is a domain selected and owned by our Client), where we perform functional and performance tests.
              </p>
              <h3>After-sales service</h3>
              <p>
                We provide constant care in the field of content updates and security. We take care of the development and expansion of the websites with new required functionalities. We have the tools, knowledge, and competencies to make you feel safe and comfortable.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default SerwisyInternetowe;
